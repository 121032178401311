<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading title="Formulare Elemente" />

        <div>
          <FormKit
            type="checkbox"
            :toggle="true"
            label="Floating Labels"
            @click="floatingLabel = !floatingLabel"
          />
          <FormKit
            type="checkbox"
            :toggle="true"
            label="Disabled State"
            @click="disabled = !disabled"
          />

          <FormKit
            type="checkbox"
            :toggle="true"
            label="Character Count"
            @click="charCount = !charCount"
          />

          <FormKit
            type="text"
            label="Max Length"
            @input="maxlength = $event === '' ? undefined : $event"
          />
        </div>
      </PageGridColumn>

      <PageGridColumn>
        <FormKit type="form">
          <div class="space-y-4">
            <Divider label="Select" class="pt-8" />

            <FormKit
              type="select"
              label="Select"
              name="select"
              placeholder="Select an option"
              :floating-label
              :options="[
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
              ]"
            />

            <Divider
              label="Family-Text (text, email, password, search)"
              class="pt-8"
            />
            <FormKit
              type="text"
              label="Text"
              placeholder="Enter your name"
              name="name1"
              :char-count
              :maxlength
              :floating-label
              :disabled
              validation="required"
            />

            <FormKit
              type="email"
              label="Email"
              name="email"
              placeholder="Enter your email"
              :disabled
              :maxlength
              :char-count
              :floating-label
              validation="email|required"
            />
            <FormKit
              type="password"
              label="Password"
              name="password"
              placeholder="Enter your password"
              :disabled
              :maxlength
              :char-count
              :floating-label
              validation="required"
            />

            <FormKit
              type="search"
              label="Search"
              :disabled
              placeholder="Foo Placeholder"
              name="search"
              validation="required"
            />

            <FormKit
              type="search"
              prefix-icon="search"
              placeholder="Shrinking Search Placeholder"
              name="search2"
              :disabled
              :collapse="true"
              :classes="{
                inner: '!rounded-full',
                input: '!rounded-full',
              }"
              help="Search takes extra optional attribute collapse"
              validation="required"
            />

            <Divider label="Textarea" class="pt-8" />
            <FormKit
              type="textarea"
              label="Textarea"
              name="message"
              placeholder="Enter your message"
              :floating-label
              :char-count
              :disabled
              :maxlength
              validation="required"
            />

            <Divider label="Number" class="pt-8" />
            <FormKit
              name="step1"
              label="Step"
              help="min 1, max 10"
              type="number"
              :disabled
              :classes="{
                wrapper: 'grid grid-cols-[minmax(0,1fr),220px]',
                help: 'text-right',
              }"
              :value="5"
              :min="1"
              :max="10"
            />

            <FormKit
              name="step1"
              label="Step"
              help="min 0, max 10, deletable"
              type="number"
              :disabled
              :deletable="true"
              :classes="{
                wrapper: 'flex justify-between',
                label: 'inline-flex',
                help: 'text-right',
                inner: 'w-[128px]',
              }"
              :value="5"
              :min="0"
              :max="10"
              validation="between:18,25"
            />

            <Divider label="Family-Box (checkbox, toggle,radio)" class="pt-8" />
            <FormKit
              type="checkbox"
              label="Checkbox Label"
              name="checkbox"
              :disabled
              validation="required"
            />

            <FormKit
              type="checkbox"
              label="Checkbox Multiple Choice Label"
              :disabled
              :options="[
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
              ]"
              name="checkbox"
              validation="required"
            />

            <FormKit
              type="checkbox"
              label="Toggle Label"
              :toggle="true"
              name="toggle"
              :disabled
              validation="required"
            />

            <FormKit
              type="checkbox"
              label="Toggle Multiple Choice Label"
              :disabled
              :toggle="true"
              :options="[
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
              ]"
              name="toggle-multiple-choice"
              validation="required"
            />

            <FormKit
              type="radio"
              label="Radio"
              name="mist"
              :disabled
              :options="[
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
              ]"
            />

            <FormKit
              type="radio"
              label="Design"
              name="design"
              :disabled
              :options="[
                {
                  label: 'Option 1',
                  value: 'option1',
                },
                { label: 'Option 2', value: 'option2' },
              ]"
              :classes="{
                options: 'grid !grid-cols-3 !gap-4',
                decorator: '!hidden',
                wrapper:
                  '!gap-0 data-[checked]:formkit-border-focus border formkit-background rounded-md',
                option:
                  'hover:formkit-background-hover rounded-md focus-within:formkit-focus overflow-hidden !p-0',
              }"
            >
              <template #label="{ option, value }">
                <div>
                  <img
                    class="block h-auto w-full rounded-md"
                    src="https://da5e5tmt16x5a.cloudfront.net/images/film/bob-marley-one-love-31176.v17221349086720.jpg?w=200&h=100&fm=webp&q=90&fit=crop"
                  />
                </div>
              </template>
            </FormKit>

            <FormKit
              type="radio"
              label="Payments"
              name="kacke"
              :disabled
              :classes="{
                option:
                  'border formkit-background hover:formkit-background-hover rounded-md p-2 focus-within:formkit-focus',
              }"
              :options="[
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
              ]"
            >
              <template #suffix="{ option, value }">
                <div v-if="value === option.value">
                  <span class="text-sm text-gray-500">make active foo</span>
                </div>
              </template>
            </FormKit>
          </div>
        </FormKit>
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script setup>
const floatingLabel = ref(false)
const disabled = ref(false)
const charCount = ref(false)
const maxlength = ref(undefined)

defineI18nRoute({
  paths: {
    en: '/forms',
    de: '/formulare',
    es: '/formularios',
  },
})
</script>
